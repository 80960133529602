const heading = (theme) => {
  return {
    color: theme.colors.text,
    fontFamily: theme.fonts.heading,
    fontWeight: theme.fontWeights.heading,
    textRendering: 'optimizeLegibility'
  }
}

export const themeLightBase = {
  colorModeTransition: 'background 0.25s var(--ease-in-out-quad), color 0.25s var(--ease-in-out-quad)',
  space: [0, 10, 20, 40, 60, 80, 100],
  fonts: {
    body: '"Kalam", system-ui, sans-serif',
    heading: '"Fredericka the Great", fantasy',
    monospace: 'Menlo, monospace',
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 80],
  fontWeights: {
    body: 400,
    heading: 400,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  card: {
    width: 340,
    height: 180,
    space: 40
  },
  githubCard: {
    width: 340,
    height: 220,
    space: 40
  },
  colors: {
    text: '#505050',
    background: '#fff9e5',
    primary: '#07c',
    secondary: '#895ff8',
    muted: '#cbcbcb',
  },
}

export const themeLight = {
  ...themeLightBase,
  styles: {
    root: {
      fontFamily: themeLightBase.fonts.body,
      fontWeight: themeLightBase.fontWeights.body,
      lineHeight: themeLightBase.lineHeights.body,
    },
    typography: {
      text: {
        fontFamily: 'inherit',
        fontSize: 20,
        lineHeight: '20px',
        paddingTop: '5px',
        marginBottom: '-5px',
        letterSpacing: '0.1em'
      },
      smallText: {
        fontFamily: 'inherit',
        fontSize: 14,
        lineHeight: '20px',
        paddingTop: '5px',
        marginBottom: '-5px',
        letterSpacing: '0.1em'
      },
      card: {
        fontFamily: 'inherit',
        fontSize: 20,
        lineHeight: '20px',
        paddingTop: '5px',
        marginBottom: '-5px',
        letterSpacing: '0.05em',
        color: themeLightBase.colors.text
      },
      h1: {
        ...heading(themeLightBase),
        fontSize: 80,
        lineHeight: '80px',
        paddingTop: '10px',
        marginBottom: '-10px'
      },
      h2: {
        ...heading(themeLightBase),
        fontSize: 54,
        lineHeight: '40px',
        paddingTop: '20px',
      },
      h3: {
        ...heading(themeLightBase),
        fontSize: 41,
        lineHeight: '30px',
        paddingTop: '10px'
      },
      h4: {
        ...heading(themeLightBase),
        fontSize: 34,
        lineHeight: '25px',
        paddingTop: '15px'
      },
      h5: {
        ...heading(themeLightBase),
        fontSize: 28,
        lineHeight: '20px',
        paddingTop: '20px'
      },
      h6: {
        ...heading(themeLightBase),
        fontSize: 20,
        lineHeight: '15px',
        paddingTop: '25px'
      },
    },
    p: {
      color: themeLightBase.colors.text,
      fontFamily: themeLightBase.fonts.body,
      fontWeight: themeLightBase.fontWeights.body,
      lineHeight: themeLightBase.lineHeights.body,
    },
    a: {
      color: themeLightBase.colors.primary,
    },
    pre: {
      fontFamily: 'monospace',
      overflowX: 'auto',
      code: {
        color: 'inherit',
      },
    },
    code: {
      fontFamily: 'monospace',
      fontSize: 'inherit',
    },
    table: {
      width: '100%',
      borderCollapse: 'separate',
      borderSpacing: 0,
    },
    th: {
      textAlign: 'left',
      borderBottomStyle: 'solid',
    },
    td: {
      textAlign: 'left',
      borderBottomStyle: 'solid',
    },
    img: {
      maxWidth: '100%',
    },
  },
}


export const themeDarkBase = {
  colorModeTransition: 'background 0.25s var(--ease-in-out-quad), color 0.25s var(--ease-in-out-quad)',
  space: [0, 10, 20, 40, 60, 80, 100],
  fonts: {
    body: '"Kalam", system-ui, sans-serif',
    heading: '"Fredericka the Great", fantasy',
    monospace: 'Menlo, monospace',
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 80],
  fontWeights: {
    body: 400,
    heading: 400,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  card: {
    width: 340,
    height: 180,
    space: 40
  },
  githubCard: {
    width: 340,
    height: 220,
    space: 40
  },
  colors: {
    text: '#FFFCEA',
    background: 'hsl(230,25%,18% )',
    primary: 'hsl(260,100%,80% )',
    secondary: 'hsl(290,100%,80% )',
    muted: '#cbcbcb',
  },
}

export const themeDark = {
  ...themeDarkBase,
  styles: {
    root: {
      fontFamily: themeDarkBase.fonts.body,
      fontWeight: themeDarkBase.fontWeights.body,
      lineHeight: themeDarkBase.lineHeights.body,
    },
    typography: {
      text: {
        fontFamily: 'inherit',
        fontSize: 20,
        lineHeight: '20px',
        paddingTop: '5px',
        marginBottom: '-5px',
        letterSpacing: '0.1em'
      },
      card: {
        fontFamily: 'inherit',
        fontSize: 20,
        lineHeight: '20px',
        paddingTop: '5px',
        marginBottom: '-5px',
        letterSpacing: '0.05em',
        color: themeDarkBase.colors.text
      },
      h1: {
        ...heading(themeDarkBase),
        fontSize: 80,
        lineHeight: '80px',
        paddingTop: '10px',
        marginBottom: '-10px'
      },
      h2: {
        ...heading(themeDarkBase),
        fontSize: 54,
        lineHeight: '40px',
        paddingTop: '20px',
      },
      h3: {
        ...heading(themeDarkBase),
        fontSize: 41,
        lineHeight: '30px',
        paddingTop: '10px'
      },
      h4: {
        ...heading(themeDarkBase),
        fontSize: 34,
        lineHeight: '25px',
        paddingTop: '15px'
      },
      h5: {
        ...heading(themeDarkBase),
        fontSize: 28,
        lineHeight: '20px',
        paddingTop: '20px'
      },
      h6: {
        ...heading(themeDarkBase),
        fontSize: 20,
        lineHeight: '15px',
        paddingTop: '25px'
      },
    },
    p: {
      color: themeDarkBase.colors.text,
      fontFamily: themeDarkBase.fonts.body,
      fontWeight: themeDarkBase.fontWeights.body,
      lineHeight: themeDarkBase.lineHeights.body,
    },
    a: {
      color: themeDarkBase.colors.primary,
    },
    pre: {
      fontFamily: 'monospace',
      overflowX: 'auto',
      code: {
        color: 'inherit',
      },
    },
    code: {
      fontFamily: 'monospace',
      fontSize: 'inherit',
    },
    table: {
      width: '100%',
      borderCollapse: 'separate',
      borderSpacing: 0,
    },
    th: {
      textAlign: 'left',
      borderBottomStyle: 'solid',
    },
    td: {
      textAlign: 'left',
      borderBottomStyle: 'solid',
    },
    img: {
      maxWidth: '100%',
    },
  },
}

