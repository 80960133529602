/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from "react"
import PropTypes from "prop-types"
// import { graphql, useStaticQuery } from "gatsby"

import main from "../styles/main.css"

import styled from "@emotion/styled"
import { css, Global } from "@emotion/core"

import { ThemeProvider, useTheme } from "emotion-theming"
import Typography from "./Typography"
import { useMyTheme } from "../hooks/useMyTheme"
import { themeDark, themeLight } from "../styles/theme"
import ThemeToggle from "./ThemeToggle"

// const VerticalLine = styled.div`
//   position: absolute;
//   height: 1000px;
//   width: 2px;
//   left: 50%;
//   transform: translateX(-50%);
//   z-index: 999;
//   background-color: aqua;
// `

const StyledLayout = styled.div`
  position: relative;
  margin: 80px auto;
  max-width: 1600px;
`

// const Tile = styled.div`
//   position: absolute;
//   left: 0;
//   top: 0;
//   width: 20px;
//   height: 20px;
//   border: 1px solid black;
//   box-sizing: border-box;
//   background-color: rgba(170,216,170,0.5);
// `

const StyledMain = styled.main`
  position: relative;
  box-sizing: border-box;
  background-image: url("/bg-pattern.png");
  background-position-x: center;
  margin: 0 20px;
`

const ToggleThemeStyle = css`
  position: absolute;
  cursor: pointer;
  top: -40px;
  left: -5px;
`

const DateStyle = css`
  position: absolute;
  top: -40px;
  right: 20px;
`

const GlobalWrapper = ({children}) => {
  const globalTheme = useTheme();

  return (
    <>
      <Global
        styles={css`
          ${main}
          body {
            font-family: ${globalTheme.fonts.body};
            color: ${globalTheme.colors.text};
            background-color: ${globalTheme.colors.background};
          }
          a {
              color: ${globalTheme.colors.primary};
              &:hover {
                color: ${globalTheme.colors.secondary};
              }
          }
        `}
      />
      {children}
    </>
  )
}

const themes = {
  "light": themeLight,
  "dark": themeDark
}

const Layout = (props) => {


  const [theme, toggleTheme, componentMounted] = useMyTheme();

  useEffect(() => {
    const supportsDarkMode = () =>
      window.matchMedia("(prefers-color-scheme: dark)").matches === true

      console.log("Support DarkMode", supportsDarkMode())
  })

  if (!componentMounted) {
    return <div />
  }

  return (
    <ThemeProvider theme={themes[theme]}>
      <GlobalWrapper>
        {/*<Header siteTitle={data.site.siteMetadata.title} />*/}
        <StyledLayout>


          {/*<VerticalLine/>*/}
          {/*<Tile/>*/}
          <StyledMain>
            <ThemeToggle theme={theme} toggleTheme={toggleTheme} css={ToggleThemeStyle} />
            {/*<Typography css={ToggleThemeStyle}>*/}
            {/*  <div onClick={toggleTheme}>Toggle Theme</div>*/}
            {/*</Typography>*/}
            <Typography css={DateStyle}>
              {new Date().toDateString()}
            </Typography>
            {props.children}


          </StyledMain>

        </StyledLayout>
      </GlobalWrapper>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
