import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { useTheme } from "emotion-theming"

const HeadingWeight = {
  light: 100,
  normal: 400,
  bold: 700,
}

const StyledTypography = styled.p`
  text-align: ${props => props.align};
  max-width: ${props => props.max ? `${props.max}px` : ``};
  font-weight: ${props => HeadingWeight[props.weight]};
`

const Typography = (props) => {
  const theme = useTheme()
  return <StyledTypography css={theme.styles.typography[props.variant]} {...props}>{props.children}</StyledTypography>
}

Typography.propTypes = {
  children: PropTypes.node.isRequired,
  weight: PropTypes.oneOf(["light", "normal", "bold"]),
  align: PropTypes.oneOf(["left", "center", "right"]),
  component: PropTypes.node,
  id: PropTypes.string,
  variant: PropTypes.oneOf(["text", "card","smallText", "h1", "h2", "h3", "h4", "h5", "h6"])
}


export default Typography
